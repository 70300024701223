<template>
  <svg width="234" height="234" viewBox="0 0 234 234" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clip-path="url(#clip0_721_4338)">
  <path d="M117.002 26.1338C56.5687 -32.9274 -42.6497 66.0047 103.549 151.143C111.873 155.99 122.131 155.99 130.454 151.143C276.653 66.0047 177.435 -32.9274 117.002 26.1338Z" fill="url(#paint0_linear_721_4338)"/>
  <path d="M173.791 225.761C207.044 225.761 234 198.805 234 165.553C234 132.3 207.044 105.344 173.791 105.344C140.539 105.344 113.582 132.3 113.582 165.553C113.582 198.805 140.539 225.761 173.791 225.761Z" fill="url(#paint1_linear_721_4338)"/>
  <path d="M207.049 138.074C205.829 138.074 204.611 137.598 203.697 136.653C202.245 135.151 200.675 133.77 199.031 132.547C196.964 131.012 196.535 128.092 198.07 126.026C199.605 123.959 202.525 123.529 204.592 125.065C206.642 126.589 208.596 128.307 210.399 130.173C212.189 132.024 212.139 134.975 210.288 136.764C209.384 137.638 208.215 138.074 207.049 138.074Z" fill="#FFC8E5"/>
  <path d="M189.601 126.195C189.039 126.195 188.468 126.093 187.913 125.877C184.821 124.675 181.565 123.845 178.233 123.409C175.68 123.075 173.882 120.735 174.216 118.182C174.55 115.629 176.89 113.831 179.443 114.165C183.516 114.698 187.503 115.715 191.291 117.189C193.691 118.121 194.88 120.823 193.947 123.222C193.23 125.066 191.468 126.195 189.601 126.195Z" fill="#FFC8E5"/>
  <path d="M88.6444 97.0271C79.8834 93.3763 70.2777 91.3594 60.2092 91.3594C52.986 91.3594 46.0023 92.4012 39.3962 94.3352C45.194 103.691 53.3197 113.356 64.1825 123.084L87.7862 115.698C90.4483 114.865 92.6382 112.952 93.8213 110.426C96.2223 105.301 93.8688 99.204 88.6444 97.0271Z" fill="#F5385D"/>
  <path d="M60.2089 225.763C93.4613 225.763 120.418 198.807 120.418 165.555C120.418 132.302 93.4613 105.346 60.2089 105.346C26.9564 105.346 0 132.302 0 165.555C0 198.807 26.9564 225.763 60.2089 225.763Z" fill="url(#paint2_linear_721_4338)"/>
  <path d="M19.813 181.923C23.6046 181.923 26.6782 179.453 26.6782 176.406C26.6782 173.359 23.6046 170.889 19.813 170.889C16.0214 170.889 12.9478 173.359 12.9478 176.406C12.9478 179.453 16.0214 181.923 19.813 181.923Z" fill="#FDAECE"/>
  <path d="M100.604 181.923C104.396 181.923 107.47 179.453 107.47 176.406C107.47 173.359 104.396 170.889 100.604 170.889C96.8129 170.889 93.7393 173.359 93.7393 176.406C93.7393 179.453 96.8129 181.923 100.604 181.923Z" fill="#FDAECE"/>
  <path d="M31.5835 176.407C39.102 176.407 45.1969 170.312 45.1969 162.793C45.1969 155.275 39.102 149.18 31.5835 149.18C24.0649 149.18 17.97 155.275 17.97 162.793C17.97 170.312 24.0649 176.407 31.5835 176.407Z" fill="white"/>
  <path d="M44.9461 164.709C45.9767 160.349 43.2777 155.979 38.9177 154.948C34.5577 153.918 30.1878 156.617 29.1571 160.977C28.1265 165.337 30.8255 169.707 35.1855 170.737C39.5455 171.768 43.9155 169.069 44.9461 164.709Z" fill="#383842"/>
  <path d="M88.8344 176.407C96.353 176.407 102.448 170.312 102.448 162.793C102.448 155.275 96.353 149.18 88.8344 149.18C81.3159 149.18 75.2209 155.275 75.2209 162.793C75.2209 170.312 81.3159 176.407 88.8344 176.407Z" fill="white"/>
  <path d="M100.015 168.521C103.183 165.353 103.183 160.217 100.015 157.049C96.8469 153.881 91.7105 153.881 88.5424 157.049C85.3744 160.217 85.3744 165.353 88.5424 168.521C91.7105 171.69 96.8469 171.69 100.015 168.521Z" fill="#383842"/>
  <path d="M60.2089 181.088C63.5729 181.088 66.2999 177.517 66.2999 173.112C66.2999 168.707 63.5729 165.137 60.2089 165.137C56.8449 165.137 54.1179 168.707 54.1179 173.112C54.1179 177.517 56.8449 181.088 60.2089 181.088Z" fill="#383842"/>
  <path d="M133.395 181.923C137.187 181.923 140.261 179.453 140.261 176.406C140.261 173.359 137.187 170.889 133.395 170.889C129.604 170.889 126.53 173.359 126.53 176.406C126.53 179.453 129.604 181.923 133.395 181.923Z" fill="#FF748F"/>
  <path d="M214.187 181.923C217.978 181.923 221.052 179.453 221.052 176.406C221.052 173.359 217.978 170.889 214.187 170.889C210.395 170.889 207.322 173.359 207.322 176.406C207.322 179.453 210.395 181.923 214.187 181.923Z" fill="#FF748F"/>
  <path d="M145.165 176.407C152.684 176.407 158.779 170.312 158.779 162.793C158.779 155.275 152.684 149.18 145.165 149.18C137.647 149.18 131.552 155.275 131.552 162.793C131.552 170.312 137.647 176.407 145.165 176.407Z" fill="white"/>
  <path d="M145.369 168.525C148.537 165.357 148.537 160.221 145.369 157.053C142.201 153.885 137.064 153.885 133.896 157.053C130.728 160.221 130.728 165.357 133.896 168.525C137.064 171.693 142.201 171.693 145.369 168.525Z" fill="#383842"/>
  <path d="M202.417 176.407C209.935 176.407 216.03 170.312 216.03 162.793C216.03 155.275 209.935 149.18 202.417 149.18C194.898 149.18 188.803 155.275 188.803 162.793C188.803 170.312 194.898 176.407 202.417 176.407Z" fill="white"/>
  <path d="M204.819 164.748C205.85 160.388 203.151 156.018 198.791 154.987C194.431 153.957 190.061 156.656 189.03 161.016C188 165.376 190.699 169.746 195.059 170.776C199.419 171.807 203.789 169.108 204.819 164.748Z" fill="#383842"/>
  <path d="M177.28 166.102H170.302C168.92 166.102 167.701 167.01 167.312 168.336C166.083 172.514 164.673 181.091 173.791 181.091C182.91 181.091 181.499 172.514 180.27 168.336C179.88 167.01 178.662 166.102 177.28 166.102Z" fill="#383842"/>
  <path d="M73.1144 79.951C77.2337 79.951 80.573 77.2673 80.573 73.9569C80.573 70.6465 77.2337 67.9629 73.1144 67.9629C68.9951 67.9629 65.6558 70.6465 65.6558 73.9569C65.6558 77.2673 68.9951 79.951 73.1144 79.951Z" fill="#FDAECE"/>
  <path d="M160.886 79.951C165.005 79.951 168.345 77.2673 168.345 73.9569C168.345 70.6465 165.005 67.9629 160.886 67.9629C156.767 67.9629 153.427 70.6465 153.427 73.9569C153.427 77.2673 156.767 79.951 160.886 79.951Z" fill="#FDAECE"/>
  <path d="M85.9009 61.147C89.4878 61.147 92.7983 61.9403 95.4679 63.2809C97.7365 64.4202 100.442 62.8805 100.644 60.3499C100.7 59.6395 100.706 58.9142 100.658 58.1786C100.164 50.6156 93.8662 44.5763 86.2896 44.3819C77.944 44.168 71.1113 50.8696 71.1113 59.1668C71.1113 59.5877 71.129 60.0049 71.1635 60.417C71.3742 62.9188 74.0881 64.409 76.3316 63.2823C79.0016 61.9413 82.313 61.147 85.9009 61.147Z" fill="white"/>
  <path d="M117 79.0434C113.78 79.0434 110.561 77.9675 107.875 75.8154C105.383 73.8194 104.982 70.1817 106.978 67.6902C108.974 65.1982 112.611 64.7974 115.103 66.7929C116.22 67.6879 117.78 67.6879 118.897 66.7929C121.387 64.7969 125.025 65.1982 127.022 67.6902C129.018 70.1817 128.616 73.8194 126.125 75.8154C123.439 77.9675 120.22 79.0434 117 79.0434Z" fill="#383842"/>
  <path d="M85.9011 61.1487C88.8601 61.1487 91.6303 61.689 94.009 62.6301C94.464 61.5669 94.7157 60.3978 94.7157 59.1681C94.7157 54.2998 90.7694 50.3535 85.9011 50.3535C81.0327 50.3535 77.0864 54.2998 77.0864 59.1681C77.0864 60.3978 77.3381 61.5669 77.7931 62.6301C80.1722 61.689 82.9425 61.1487 85.9011 61.1487Z" fill="#383842"/>
  <path d="M148.099 61.147C151.686 61.147 154.997 61.9403 157.666 63.2809C159.935 64.4202 162.641 62.8805 162.842 60.3499C162.898 59.6395 162.905 58.9142 162.857 58.1786C162.362 50.6156 156.064 44.5763 148.488 44.3819C140.142 44.168 133.31 50.8696 133.31 59.1668C133.31 59.5877 133.327 60.0049 133.362 60.417C133.572 62.9188 136.286 64.409 138.53 63.2823C141.199 61.9413 144.511 61.147 148.099 61.147Z" fill="white"/>
  <path d="M148.099 61.1487C151.058 61.1487 153.829 61.689 156.207 62.6301C156.662 61.5669 156.914 60.3978 156.914 59.1681C156.914 54.2998 152.968 50.3535 148.099 50.3535C143.231 50.3535 139.285 54.2998 139.285 59.1681C139.285 60.3978 139.536 61.5669 139.991 62.6301C142.37 61.689 145.14 61.1487 148.099 61.1487Z" fill="#383842"/>
  </g>
  <defs>
  <linearGradient id="paint0_linear_721_4338" x1="117.002" y1="8.23651" x2="117.002" y2="154.778" gradientUnits="userSpaceOnUse">
  <stop stop-color="#FF889E"/>
  <stop offset="0.9788" stop-color="#F5385D"/>
  </linearGradient>
  <linearGradient id="paint1_linear_721_4338" x1="113.582" y1="165.553" x2="234" y2="165.553" gradientUnits="userSpaceOnUse">
  <stop offset="0.0212" stop-color="#FFB3DD"/>
  <stop offset="1" stop-color="#FF88CA"/>
  </linearGradient>
  <linearGradient id="paint2_linear_721_4338" x1="120.418" y1="165.555" x2="0" y2="165.555" gradientUnits="userSpaceOnUse">
  <stop offset="0.0212" stop-color="#BA99DB"/>
  <stop offset="1" stop-color="#8959C3"/>
  </linearGradient>
  <clipPath id="clip0_721_4338">
  <rect width="234" height="234" fill="white"/>
  </clipPath>
  </defs>
  </svg>
</template>